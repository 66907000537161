import React from 'react'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby";
import Banner from '../../components/ads/banner'

function HowToCalculateTax() {
    return (
        <Layout>
            <div className='row mb-4'>
                <div className='col-12'>
                    <h2>Common Details of Income Tax Calculation</h2>
                    <h5><strong>What is a "Return of Income"?</strong></h5>
                    <p>The Return of Income is a Form filed by a person to the Income Tax Department. It contains details of the person‟s annual income(s).</p>
                    <h3>Obligation to File Income Tax Declarations</h3>
                    <h5><strong>Who is required to submit a return of income?</strong></h5>
                    <p><strong>1.</strong> Every company irrespective of any conditions, whether earning exempt income, or still in start-up stage or any other situation;</p>
                    <p><strong>2.</strong> Associations of persons and individuals with annual income over Rs. 600,000/- for tax year 2023.</p>
                    <p><strong>3.</strong> Any person who owns immovable property: <strong>1.</strong> with land area of 250 sq. yards or more located in areas falling within the municipal limits, a Cantonment Board, or the Islamabad Capital Territory; <strong>2.</strong> with land area of 500 sq. yards or more located in rating area; or <strong>3.</strong> a flat located in areas falling within the municipal limits, a Cantonment Board, or the Islamabad Capital Territory; or <strong>4.</strong> a flat with covered area of 2,000 sq. feet or more located in rating areas;</p>
                    <p><strong>4.</strong> any person who owns a motor vehicle having engine capacity of 1,000 cc or more</p>
                    <p><strong>5.</strong> any person who has obtained a National Tax Number; or</p>
                    <p><strong>6.</strong> any person who is the holder of commercial or industrial connection of electricity where the amount of annual bill exceeds Rs. 1,000,000.</p>
                    <p><strong>7.</strong> Non profit organizations, irrespective of any conditions;</p>
                    <p><strong>8.</strong> Any welfare institution approved under Clause (58) of Part I of the Second Schedule to the Income Tax Ordinance, 2001, irrespective of any conditions.</p>
                    <p><strong>9.</strong> Every individual whose income under the head „Income from business‟ exceeds Rs. 300,000 but does not exceed the maximum amount that is not chargeable to tax.</p>
                    <p><strong>10.</strong> Any person [who in the opinion of the Commissioner was required to furnish the return of income (for any of the aforesaid reasons) but has failed to do so] to whom a notice for furnishing of the return of income has been served by the Commissioner.</p>
                    <p>Even if none of the above applies, one may still need to file a return in order to claim a refund of tax deducted or collected at source (other than final tax).</p>
                    <div className='mt-2 mb-2 text-center'>
                        <Banner></Banner>
                    </div>
                    <h5><strong>Who is not required to file I.T Tax Return?</strong></h5>
                    <p>Exceptions (not required to file return of income) to the above are as under:</p>
                    <p><strong>1.</strong> Deriving income exclusively from salary upto Rs. 599,999 (The annual statement of deduction of income tax from salary, filed by the employer is treated as return of income on behalf of such employee);</p>
                    <p><strong>2.</strong> Entire income is subject to final tax or fixed tax for which a separate statement is prescribed;</p>
                    <p><strong>3.</strong> A widow, an orphan below the age of 25 years or a disabled person solely for the reason of owning immovable property (a flat or land of 250 sq. yards or more) located in areas falling within the municipal limits*, a Cantonment Board, or the Islamabad Capital Territory; and</p>
                    <p><strong>4.</strong> A non-resident for the reason of owning immovable property;</p>
                    <h5><strong>Is it mandatory to furnish the return of income and/or statement of final/fixed tax electronically?</strong></h5>
                    <p>Electronic filing of return of income and/or statement of final/fixed tax is mandatory for the following:</p>
                    <p><strong>1.</strong> A company;</p>
                    <p><strong>2.</strong> An Association of Persons; and</p>
                    <p><strong>3.</strong> An individual registered under the Sales Tax Act, 1990</p>
                    <p><strong>4.</strong> An individual deriving income from salary of Rs. 600,000 or more.</p>
                    <p>For further information on the procedure for efiling please visit FB's web portal www.e.fbr.gov.pk</p>
                    <h5><strong>In case of refund what is required to be done?</strong></h5>
                    <p>If computation of tax results in a refund, you are required to submit a separate application for claim of refund in the prescribed form with the return of income and/or statement of final tax.</p>
                    <p>The refund can also be claimed later on (after you have submitted your tax returns) but within two years from the date of assessment (date of filling of return etc.) or from the date on which the tax was paid, which ever is later.</p>
                    <h5><strong>Are tax rates for salaried persons of federal and provincial governments different?</strong></h5>
                    <p>Tax rates for salaried income of all classes of person are same however, there are different rates for different <Link to='/tax-slabs-2023-2024/'>income slabs</Link>.</p>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
        </Layout>
    );
}

export const Head = () => <Seo title="Common Details of Income Tax Calculation | Tax Calculator Pakistan" description="Calculating taxes can be a complex process, but by understanding the tax laws and regulations in Pakistan, individuals and businesses can ensure compliance and avoid any penalties." />

export default HowToCalculateTax;